/* global
 Sentry
 gettext
 */

// #############################################################################
// GLOBAL VARS

const $window = $(window);
const $body = $("body");

// #############################################################################
// SENTRY

function initSentry () {
  const dsn = $body.data("sentry-dsn");

  if (!dsn) {
    return;
  }

  Sentry.init({
    dsn: dsn,
    environment: $body.data("sentry-env"),
    release: $body.data("version"),
  });
}

initSentry();

// #############################################################################
// AJAX SETUP

function csrfSafeMethod (method) {
  // these HTTP methods do not require CSRF protection
  return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}

$.ajaxSetup({
  cache: false,
  beforeSend: function ($xhr, $settings) {
    if (!csrfSafeMethod($settings.type) && !this.crossDomain) {
      $xhr.setRequestHeader(
        "X-CSRFToken", $("[name=csrfmiddlewaretoken]").val()
      );
    }
  },
  error: function (e) {
    console.log("ERROR:", e, e.status, e.statusText);
  },
});

// #############################################################################
//  HELPERS

const $x = {};

$x.ajax = {
  $defaults: {
    dataType: "text",
    error: ($xhr) => {
      console.log("ERROR:", $xhr, $xhr.status, $xhr.statusText);
    },
    success: ($data) => {
    },
  },

  get (url, $settings = {}) {
    const _this = this;
    const $xhr = new XMLHttpRequest();

    $settings = { ..._this.$defaults, ...$settings };

    $xhr.open("GET", url);

    $xhr.onreadystatechange = () => {
      if ($xhr.readyState === XMLHttpRequest.DONE) {
        const status = $xhr.status;

        if (status === 0 || (status >= 200 && status < 400)) {
          // The request has been completed successfully

          if ($settings.success) {
            if ($settings.dataType === "json") {
              $settings.success(JSON.parse($xhr.responseText));
            } else {
              $settings.success($xhr.responseText);
            }
          }
        } else {
          $settings.error($xhr);
        }
      }
    };

    // The header X-Requested-With allows server side frameworks, such as Django,
    // to identify Ajax requests. It's optional, but can be useful.
    $xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    $xhr.send();

    return $xhr;
  },
};

$x.delegateEvent = {
  on ($element, events, selector, handler) {
    $element.addEventListener(events, function (e) {
      const $elements = document.querySelectorAll(selector);

      $elements.forEach(function ($element) {
        if ($element.contains(e.target)) {
          return handler.apply($element, [e]);
        }
      });
    });
  },
};

$x.html = function ($element, html) {
  const $oldElement = $element;
  const $newElement = $oldElement.cloneNode(false);

  $newElement.innerHTML = html;
  $oldElement.parentNode.replaceChild($newElement, $oldElement);

  return $newElement;
};

$x.insertAfter = function ($newElement, $element) {
  $element.parentNode.insertBefore($newElement, $element.nextSibling);
};

$x.remove = function ($elements) {
  $elements.forEach(function ($element) {
    $element.parentNode.removeChild($element);
  });
};

$x.replaceHtml = function ($data, $parent = document) {
  /**
   * replaceHtml() updates multiple HTML content from ajax response.
   *
   * Args:
   *  $data (obj): JSON object
   *
   * Example:
   *  $data = {
   *    "updates": [
   *      {
   *        "wrapper": "#id_to_insert_content",
   *        "content": "<p>Updated content</p>",
   *      },
   *    ],
   *  }
   *
   **/

  if ($data.updates) {
    $data.updates.forEach(function ($item) {
      const $elements = document.querySelectorAll($item.wrapper);

      $elements.forEach(function ($element) {
        $x.html($element, $item.content);
      });
    });
  }
};

/**
 * Modal:
 *
 * $x.modal.open(url, {
 *  beforeModalOpen: function ($modal, $data) {
 *    // $modal = jQuery object from the <div data-modal>
 *    // $data = JSON response
 *  },
 *  onModalOpen: function ($modal, $data) {
 *    // $modal = jQuery object from the <div data-modal>
 *    // $data = JSON response
 *  },
 *  onModalClose: function ($modal) {
 *    // $modal = jQuery object from the <div data-modal>
 *  },
 * });
 *
 **/

$x.modal = {
  $defaults: {
    beforeModalOpen: ($modal, $data) => {
      if ($data.submit === "error") {
        if ($data.toaster) {
          $body.toaster("updateToaster", $data.toaster);
        }
      }
    },
    onModalOpen: ($modal, $data) => {
    },
    onModalClose: ($modal) => {
    },
  },

  open (url, $settings = {}) {
    const _this = this;
    const $modalWrapper = $("#modal_wrapper");

    // WORKAROUND: backdrop not removed when modal open other modal!
    $x.remove(document.querySelectorAll(".modal-backdrop"));

    $settings = { ..._this.$defaults, ...$settings };

    $x.ajax.get(url, {
      success: function ($data) {
        $modalWrapper.html($data);

        const $modal = $("[data-modal]", $modalWrapper).modal();

        $settings.beforeModalOpen($modal, $data);

        $modal.on("shown.bs.modal", () => {
          $settings.onModalOpen($modal, $data);
        });

        $modal.on("hidden.bs.modal", () => {
          $settings.onModalClose($modal);
          $modalWrapper.empty();
        });
      },
    });
  },
};

/**
 * $x.formatFileSize() outputs human readable file size.
 *
 * Args:
 *  bytes (int): Bytes
 *  decimal_point (int): Decimal point
 *
 * Returns:
 *  str: A human readable string with unit.
 *
 **/

$x.formatFileSize = function (bytes, decimal_point) {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1000;
  const dm = decimal_point || 2;
  const sizes = ["Bytes", "kb", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

/**
 * $x.escapeText() escape HTML tags from string.
 *
 **/

$x.escapeText = function (text) {
  const tags_to_replace = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
  };

  return text.replace(/[&<>]/g, function (tag) {
    return tags_to_replace[tag] || tag;
  });
};

/**
 * $x.removeHtml() remove HTML tags from string.
 *
 **/

$x.removeText = function (text) {
  return text.replace(/<\/?[^>]+(>|$)/g, "").trim();
};

// #############################################################################
// JQUERY PLUGIN HELPER

function initPlugin (Plugin, plugin_name) {
  return function ($options) {
    const $args = Array.prototype.slice.call(arguments, 1);

    if ($options === undefined || typeof $options === "object") {
      return this.each(function () {
        if (!$.data(this, "plugin_" + plugin_name)) {
          $.data(this, "plugin_" + plugin_name, new Plugin(this, $options));
        }
      });
    } else if (typeof $options === "string") {
      this.each(function () {
        const $instance = $.data(this, "plugin_" + plugin_name);

        if ($instance && typeof $instance[$options] === "function") {
          $instance[$options].apply($instance, $args);
        } else {
          throw new Error("Method " + $options + " does not exist on jQuery." + plugin_name);
        }
      });
    }
  };
}

// #############################################################################
// REDIRECT

function checkRedirect ($data) {
  if ($data.toaster) {
    $body.toaster("saveToaster", $data.toaster);
  }

  window.location.href = $data.redirect;
}

// #############################################################################
// FOCUS

/**
 * Initial:
 *
 * $("body").betterFocus({
 *  selector: "a, [tabindex]",
 * });
 *
 **/

(function ($) {
  "use strict";

  const plugin_name = "betterFocus";

  const $defaults = {
    selector: "a, [tabindex]",
  };

  class betterFocusPlugin {
    constructor ($element, $options) {
      this.$settings = $.extend({}, $defaults, $options);

      this.el = $element;
      this.$el = $($element);

      this.focus_method = false;
      this.last_focus_method = false;

      this.init();
    }

    init () {
      const _this = this;

      this.$el.on("focus", _this.$settings.selector, function () {
        if (!_this.focus_method) {
          _this.focus_method = _this.last_focus_method;
        }

        $(_this.$settings.selector).attr("data-focus-method", _this.focus_method);

        _this.last_focus_method = _this.focus_method;
        _this.focus_method = false;
      });

      $body.on("blur", _this.$settings.selector, function () {
        $(_this.$settings.selector).removeAttr("data-focus-method");
      });

      $window.on("blur", function () {
        _this.focus_method = false;
      });

      // Keyboard

      $body.on("keydown", _this.$settings.selector, function () {
        _this.focus_method = "key";
      });

      // Mouse

      $body.on("mousedown", _this.$settings.selector, function () {
        if (_this.focus_method === "touch") {
          return;
        }

        _this.focus_method = "mouse";
      });

      // Touch

      $body.on("touchstart", _this.$settings.selector, function () {
        _this.focus_method = "touch";
      });
    }
  }

  $.fn[plugin_name] = initPlugin(betterFocusPlugin, plugin_name);
})(jQuery);

// #############################################################################
// DATA TABLE

/**
 * Initial:
 *
 * $("[data-table]").xDataTable({
 *  options: {
 *    // Documentation https://datatables.net/reference/option/
 *  },
 *  onInit: function ($table, $json) {
 *    // $table = jQuery object from the [data-table]
 *    // $json = JSON data retrieved from the server, if Ajax loading data
 *  },
 *  onStateLoaded: function ($table, $data) {
 *    // $table = jQuery object from the [data-table]
 *    // $data = State information read from storage
 *  },
 *  onDraw: function ($table) {
 *    // $table = jQuery object from the [data-table]
 *  },
 *  customizeCSV: function (csv) {
 *    // csv = CSV data
 *  }
 * });
 *
 * Reload:
 *
 * $("[data-table]").xDataTable("reload");
 *
 **/

(function ($) {
  "use strict";

  const plugin_name = "xDataTable";

  const $defaults = {
    options: {},
    buttons: {},
    onInit: function ($table, $json) {
    },
    onStateLoaded: function ($table, $data) {
    },
    onDraw: function ($table) {
    },
    customizeCSV: function (csv) {
      return csv;
    },
    rowGroupStartRender: function ($table, $rows, html) {
      return html;
    },
    rowGroupEndRender: function ($table, $rows, html) {
      return html;
    },
    api: function ($table, $api) {
    },
  };

  class xDataTablePlugin {
    constructor ($element, $options) {
      this.$settings = $.extend({}, $defaults, $options);

      this.$el = $($element);
      this.$element = $element;

      this.$fields = JSON.parse($element.getAttribute("data-fields"));
      this.url = $element.getAttribute("data-table");
      this.csfr_token = $element.getAttribute("data-csrf-token");

      this.$buttons = $("[data-button=\"" + $element.id + "\"]");
      this.$reset = $("[data-reset=\"" + $element.id + "\"]");
      this.$filter = $("[data-filter=\"" + $element.id + "\"]");
      this.$filter_counter = $("[data-filter-counter=\"" + $element.id + "\"]");

      this.$inputs = $("[data-input]", this.$filter);
      this.$selects = $("[data-select]", this.$filter);

      this.$processing = $("[data-processing]", $element);
      this.$last_updated = $("[data-last-updated]", $element);

      this.init();
    }

    init () {
      const _this = this;

      if (!$.fn.dataTable) {
        return;
      }

      $.fn.dataTable.ext.errMode = "throw";

      _this._setClasses();

      const $defaults = _this._initDefaults();

      _this.$api = _this.$el.DataTable($defaults);

      _this.$settings.api(_this.$el, _this.$api);

      _this._hideColumns();
      _this._initButtons();
      _this._initFilters();
      _this._initEventListener();
      _this._checkFilters();
    }

    reload ($args) {
      const _this = this;
      const top = $(window).scrollTop();

      if ($args) {
        const url = $args.url;

        if (url) {
          _this.$api.ajax.url(url);
        }
      }

      _this.$api.ajax.reload(function () {
        $(window).scrollTop(top);
      }, false);
    }

    _autoReload () {
      const _this = this;
      const autoreload = _this.$el.data("autoreload");

      if (autoreload) {
        setInterval(function () {
          _this.reload();
        }, autoreload);
      }
    }

    _restoreColumnsClass () {
      const _this = this;
      const column_classes = [];

      _this.$api.rows().every(function (index) {
        const $row = _this.$api.row(index).nodes();
        const $columns = $("td", $row);
        const column_class = [];

        $columns.each(function (index) {
          const $column = $columns.eq(index);

          column_class.push($column.attr("class"));
        });

        column_classes.push(column_class);
      });

      _this.$api.one("draw.dtr", function () {
        _this.$api.rows().every(function (index) {
          const $row = _this.$api.row(index).nodes();
          const $columns = $("td", $row);
          const column_class = column_classes[index];

          if (column_class) {
            $columns.each(function (index) {
              const $column = $columns.eq(index);

              $column.attr("class", column_class[index]);
            });
          }
        });
      });
    }

    _hideColumns () {
      const _this = this;

      for (let i = 0; i < _this.$fields.length; i++) {
        if (_this.$fields[i].hide) {
          _this.$api.column(i).visible(false);
        }
      }
    }

    _initEventListener () {
      const _this = this;

      _this.$api.on("init", function (event, $settings, $json) {
        _this._lastUpdated();

        _this.$settings.onInit(_this.$el, $json);
      });

      _this.$api.on("stateLoaded", function (event, $settings, $data) {
        _this.$settings.onStateLoaded(_this.$el, $data);
      });

      _this.$api.on("draw", function (event, $settings) {
        _this.$settings.onDraw(_this.$el);
      });

      _this.$api.on("preXhr.dtr", function () {
        _this._restoreColumnsClass();
      });

      _this.$api.on("processing.dt", function (event, $settings, processing) {
        _this._processingIndicator(processing);
      });
    }

    _processingIndicator (processing) {
      const _this = this;

      if (processing) {
        _this._lastUpdated();
        _this.$processing.prop("hidden", false);
      } else {
        _this.$processing.prop("hidden", true);
      }
    }

    _lastUpdated () {
      const _this = this;

      if (_this.$last_updated.length === 0) {
        return;
      }

      const text = _this.$last_updated.data("text");
      const text_plural = _this.$last_updated.data("text-plural");
      let seconds = 1;

      clearInterval(_this.last_updated);

      _this.$last_updated.text(
        text.replace("[seconds]", seconds)
      );

      _this.last_updated = setInterval(function () {
        seconds += 1;

        _this.$last_updated.text(
          text_plural.replace("[seconds]", seconds)
        );
      }, 1000);
    }

    _defaultButtons () {
      const _this = this;

      const $buttons = [];

      if (_this.$element.hasAttribute("data-export-csv")) {
        $buttons.push({
          extend: "csv",
          exportOptions: {
            columns: "thead th:not(.no-export-csv)",
          },
          customize: function (csv) {
            return _this.$settings.customizeCSV(csv);
          },
        });
      }

      $.merge($buttons, _this.$settings.buttons);

      return $buttons;
    }

    _initButtons () {
      const _this = this;

      _this.$buttons.on("click", function () {
        const button_action = this.getAttribute("data-button-action");

        $("." + button_action).click();
      });
    }

    _initDefaults () {
      const _this = this;

      let $defaults = {
        autoWidth: false,
        ajax: {
          beforeSend: function ($xhr) {
            $xhr.setRequestHeader("X-CSRFToken", _this.csfr_token);
          },
          type: "POST",
          url: _this.url,
        },
        buttons: {
          buttons: _this._defaultButtons(),
        },
        columnDefs: [
          {
            sortable: false,
            targets: "no-sort",
          },
        ],
        language: {
          sEmptyTable: gettext("No data available"),
          sInfo: gettext("Showing _START_ to _END_ of _TOTAL_ entries"),
          sInfoEmpty: gettext("Showing 0 to 0 of 0 entries"),
          sInfoThousands: ".",
          sLengthMenu: gettext("Show _MENU_ entries"),
          sProcessing: gettext("Please wait ..."),
          sZeroRecords: gettext("No matching entries found"),
          paginate: {
            next: gettext("Next"),
            previous: gettext("Previous"),
          },
          aria: {
            sortAscending: gettext(": activate to sort column ascending"),
            sortDescending: gettext(": activate to sort column descending"),
          },
        },
        lengthMenu: [
          [10, 50, 100, -1],
          [10, 50, 100, gettext("All")],
        ],
        ordering: true,
        pagingType: "simple_numbers",
        processing: true,
        serverSide: true,
        stateSave: true,
        stateLoaded: function ($settings, $data) {
          _this._setStateFilters($data);
        },
        initComplete: function () {
          _this._autoReload();
        },
      };

      _this.$settings.options.columns = _this._loadDynamicFields();

      $.extend($defaults, _this.$settings.options);

      $defaults = _this._initRowGroup($defaults);

      return $defaults;
    }

    _initRowGroup ($defaults) {
      const _this = this;
      const $dataset = _this.$element.dataset;
      const groupedBy = $dataset.rowGroupedBy;

      if (!groupedBy) {
        return $defaults;
      }

      const $rowGroupDefaults = {
        rowGroup: {
          dataSrc: groupedBy,
        },
      };

      if ($dataset.rowHeader === "true") {
        $rowGroupDefaults.rowGroup.startRender = function ($rows, html) {
          return _this.$settings.rowGroupStartRender(_this.$el, $rows, html);
        };
      }

      if ($dataset.rowFooter === "true") {
        $rowGroupDefaults.rowGroup.endRender = function ($rows, html) {
          return _this.$settings.rowGroupEndRender(_this.$el, $rows, html);
        };
      }

      $.extend($defaults, $rowGroupDefaults);

      return $defaults;
    }

    _checkFilters () {
      const _this = this;

      const $input_values = _this.$inputs.filter(function () {
        return $(this).val();
      });

      const $select_values = _this.$selects.filter(function () {
        return $(this).val();
      });

      if ($input_values.length > 0 || $select_values.length > 0) {
        _this.$filter_counter.text(
          $input_values.length + $select_values.length
        );

        _this.$reset.removeClass("disabled");
      } else {
        _this.$filter_counter.empty();
        _this.$reset.addClass("disabled");
      }
    }

    _initFilters () {
      const _this = this;
      const $timeouts = {};

      _this.$inputs.on("input", function () {
        const index = _this.$inputs.index(this);
        const $input = _this.$inputs.eq(index);
        const $column = _this.$api.column($input.data("column"));
        const value = this.value;

        clearTimeout($timeouts[index]);

        $timeouts[index] = setTimeout(function () {
          $column.search(value).draw();
        }, 1200);

        _this._checkFilters();
      });

      _this.$selects.on("change", function () {
        const index = _this.$selects.index(this);
        const $select = _this.$selects.eq(index);
        const column = $select.data("column");
        const $column = _this.$api.column(column);

        $column.search($select.val()).draw();

        _this._checkFilters();
      });

      // Reset filters
      _this.$reset.on("click", function () {
        _this.$api.columns().search("").draw();

        _this.$inputs.val("");

        _this.$selects.each(function (index) {
          const $select = _this.$selects.eq(index);
          const value = $("[selected]", $select).val();

          if (value) {
            $select.val(value);
          } else {
            $select.val("");
          }
        });

        _this._checkFilters();

        return false;
      });
    }

    _setClasses () {
      $.fn.dataTable.ext.classes.sInfo = "dataTables_info";
      $.fn.dataTable.ext.classes.sLength = "dataTables_length";
      $.fn.dataTable.ext.classes.sLengthSelect = "custom-select";
      $.fn.dataTable.ext.classes.sPageButton = "page-item page-link";
      $.fn.dataTable.ext.classes.sPageButtonActive = "active";
      $.fn.dataTable.ext.classes.sSortAsc = "sorting-asc";
      $.fn.dataTable.ext.classes.sSortColumn = "sorting-";
      $.fn.dataTable.ext.classes.sSortDesc = "sorting-desc";
      $.fn.dataTable.ext.classes.sTable = "data-table";
    }

    _loadDynamicFields () {
      const _this = this;
      const $columns = _this.$settings.options.columns;
      const $extended_columns = [];

      for (let i = 0; i < $columns.length; i++) {
        if ($columns[i].data === "PLACEHOLDER_FIELDS") {
          for (let i = 0; i < _this.$fields.length; i++) {
            $extended_columns.push(_this.$fields[i]);
          }
        } else {
          $extended_columns.push($columns[i]);
        }
      }

      return $extended_columns;
    }

    _setStateFilters ($data) {
      // Restore saved filter values

      $.each($data.columns, function (index) {
        const $search = $data.columns[index].search;

        if ($search) {
          const search = $search.search;

          if (search) {
            $("[data-column=" + index + "]").val(search);
          }
        }
      });
    }
  }

  $.fn[plugin_name] = initPlugin(xDataTablePlugin, plugin_name);
})(jQuery);

// #############################################################################
// DOWNLOAD BLOB

/**
 * Initial:
 *
 * const $downloadBlob = new $x.DownloadBlob({
 *   onDownloadStarted: function ($data) {
 *     // $data = JSON response
 *   },
 * });
 *
 * $downloadBlob.download(href);
 *
 **/

$x.DownloadBlob = class {
  constructor ($options) {
    const $defaults = {
      onDownloadStarted: function ($data) {
      },
    };

    this.$settings = { ...$defaults, ...$options };
  }

  download (href) {
    const _this = this;

    $.ajax({
      dataType: "json",
      type: "GET",
      url: href,
      success: function ($data) {
        _this.$settings.onDownloadStarted($data);

        if ($data.base64) {
          const $blob = _this._base64toBlob($data.base64, $data.content_type);
          _this._downloadBlob($blob, $data.file_name);
        }
      },
    });
  }

  _downloadBlob ($data, file_name) {
    const url = window.URL || window.webkitURL;
    const $a = $("<a>");

    $body.append($a);

    $a[0].href = url.createObjectURL($data);
    $a[0].download = file_name;
    $a[0].click();

    window.URL.revokeObjectURL(url);
    $a.remove();
  }

  _base64toBlob (data, content_type, slice_size = 512) {
    const $byte_characters = atob(data);
    const $byte = [];

    for (let offset = 0; offset < $byte_characters.length; offset += slice_size) {
      const slice = $byte_characters.slice(offset, offset + slice_size);

      const $byte_numbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        $byte_numbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array($byte_numbers);

      $byte.push(byteArray);
    }

    return new Blob($byte, {
      type: content_type,
    });
  }
};

// #############################################################################
// CLIPBOARD

/**
 * Initial:
 *
 * <input id="target" value="Copy text">
 * <a data-clipboard="copy" data-clipboard-target="#target">Copy</a>
 *
 * or
 *
 * <a data-clipboard data-clipboard-text="Copy text">Copy</a>
 *
 * $("body").clipBoard({
 *  selector: "[data-clipboard]",
 *  beforeCopyText: function ($target, text) {
 *    // $target = jQuery object from [data-clipboard-target]
 *    // text = Text to be copied
 *    return text;
 *  },
 * });
 *
 **/

(function ($) {
  "use strict";

  const plugin_name = "clipBoard";

  const $defaults = {
    selector: "[data-clipboard]",
    beforeCopyText: function ($target, text) {
      return text;
    },
  };

  class clipBoardPlugin {
    constructor ($element, $options) {
      this.$settings = $.extend({}, $defaults, $options);

      this.el = $element;

      this.$el = $($element);
      this.$dummy = undefined;

      this.init();
    }

    init () {
      const _this = this;

      _this.$el.on("click", _this.$settings.selector, function () {
        const $this = $(this);

        const text = $this.data("clipboard-text");
        const target = $this.data("clipboard-target");

        _this.$target = $(target);

        _this.action = $this.data("clipboard");

        if (text) {
          _this.action = "copy";

          _this._fakeSelectText(text);
        } else {
          _this._selectText();
        }

        _this._copyText();
        _this._clearSelection();

        return false;
      });
    }

    _fakeSelectText (select_text) {
      const _this = this;

      select_text = _this.$settings.beforeCopyText(
        _this.$target, select_text
      );

      _this.$dummy = $("<textarea>");
      _this.$dummy.val(select_text);

      _this.$el.append(_this.$dummy);

      _this.$dummy.get(0).select();
      _this.$dummy.get(0).setSelectionRange(0, select_text.length);

      return select_text;
    }

    _selectText () {
      const _this = this;
      let select_text;

      if (_this.$target.is("select")) {
        select_text = $("option:selected", _this.$target).text().trim();
        _this._fakeSelectText(select_text);
      } else if (_this.$target.is("input") || _this.$target.is("textarea")) {
        const is_read_only = _this.$target.attr("readonly");
        const select_text = _this.$target.val().trim();

        if (!is_read_only) {
          _this.$target.prop("readonly", true);
        }

        if (_this.action === "copy") {
          _this._fakeSelectText(select_text);
        } else {
          _this.$target.get(0).select();
          _this.$target.get(0).setSelectionRange(0, select_text.length);
        }

        if (!is_read_only) {
          _this.$target.removeAttr("readonly");
        }
      }
    }

    _clearSelection () {
      const _this = this;

      document.activeElement.blur();
      window.getSelection().removeAllRanges();

      _this.$el.focus();

      if (_this.$dummy) {
        _this.$dummy.remove();
      }
    }

    _copyText () {
      const _this = this;

      document.execCommand(_this.action);
    }
  }

  $.fn[plugin_name] = initPlugin(clipBoardPlugin, plugin_name);
})(jQuery);

// #############################################################################
// TOASTER

/**
 * Initial:
 *
 * <a data-toaster="{% url "toaster" %}" data-toaster-text="Toaster text">Link</a>
 *
 * $("body").toaster({
 *  selector: "[data-toaster]",
 * });
 *
 * Update toaster:
 *
 * $("body").toaster("updateToaster", toaster_html);
 *
 * Save toaster:
 *
 * $("body").toaster("saveToaster", toaster_html);
 *
 **/

(function ($) {
  "use strict";

  const plugin_name = "toaster";

  const $defaults = {
    selector: "[data-toaster]",
  };

  class toasterPlugin {
    constructor ($element, $options) {
      this.$settings = $.extend({}, $defaults, $options);

      this.el = $element;

      this.$el = $($element);
      this.$wrapper = $("#toaster_wrapper");

      this.init();
    }

    init () {
      const _this = this;

      _this.$el.on("click", _this.$settings.selector, function () {
        const $this = $(this);
        const toaster = $this.data("toaster");

        $.ajax({
          data: {
            success: true,
            text: $this.data("toaster-text"),
          },
          type: "POST",
          url: toaster,
          success: function ($data) {
            _this.updateToaster($data.toaster);
          },
        });
      });

      _this._restoreToaster();
    }

    _restoreToaster () {
      const _this = this;
      const toaster = sessionStorage.getItem("toaster");

      if (toaster) {
        _this.updateToaster(toaster);
        sessionStorage.removeItem("toaster");
      }
    }

    saveToaster (toaster_html) {
      sessionStorage.setItem("toaster", toaster_html);
    }

    updateToaster (toaster_html) {
      const _this = this;
      _this.$wrapper.prepend(toaster_html);

      const $toast = $(".toast:not(.fade)", _this.$wrapper);
      $toast.toast("show");

      $toast.on("show.bs.toast", function () {
        $(this).toast("dispose");
      });
    }
  }

  $.fn[plugin_name] = initPlugin(toasterPlugin, plugin_name);
})(jQuery);

// #############################################################################
// AUTO UPDATE HTML CONTENT

/**
 * Initial:
 *
 * <div data-update-html-content="/UPDATE_URL/">
 *   Auto update content inside this div.
 * </div>
 *
 * UPDATE_URL is a JSON file like in replaceHtml()
 *
 * $("body").autoUpdateHtmlContent({
 *  selector: "[data-update-html-content]",
 * });
 *
 **/

(function ($) {
  "use strict";

  const plugin_name = "autoUpdateHtmlContent";

  const $defaults = {
    selector: "[data-update-html-content]",
    update_interval: 10000,
  };

  class autoUpdateHtmlContent {
    constructor ($element, $options) {
      this.$settings = $.extend({}, $defaults, $options);

      this.$el = $($element);
      this.$wrappers = $("[data-update-html-content]", $element);

      this.init();
    }

    init () {
      const _this = this;

      _this.$wrappers.each(function () {
        const url = this.getAttribute("data-update-html-content");

        if (url) {
          const $element = this;
          const interval_time = this.getAttribute("data-update-interval") || _this.$settings.update_interval;

          _this._update($element, url);

          _this.interval = setInterval(function () {
            _this._update($element, url);
          }, interval_time);
        }
      });
    }

    _update ($wrapper, url) {
      $.ajax({
        dataType: "json",
        url: url,
        success: function ($data) {
          $x.replaceHtml($data, $wrapper);
        },
      });
    }
  }

  $.fn[plugin_name] = initPlugin(autoUpdateHtmlContent, plugin_name);
})(jQuery);

// #############################################################################
// COLLAPSE

(function initCollapse () {
  const $collapse = $(".collapse");

  function getButton (id) {
    return document.querySelector("[data-target=\"#" + id + "\"]");
  }

  function setState ($button, id, state) {
    localStorage.setItem("collapseState_" + id, state);
    $button.setAttribute("aria-pressed", state === "show" ? "true" : "false");
  }

  $collapse.each(function () {
    const $button = getButton(this.id);
    const hasAriaPressed = $button.getAttribute("aria-pressed");

    if (hasAriaPressed) {
      const collapseState = localStorage.getItem("collapseState_" + this.id);

      if (collapseState) {
        $(this).collapse(collapseState);
        setState($button, this.id, collapseState);
      } else {
        if (hasAriaPressed === "true") {
          localStorage.setItem("collapseState_" + this.id, "show");
        } else if (hasAriaPressed === "false") {
          localStorage.setItem("collapseState_" + this.id, "hide");
        }
      }
    }
  });

  $collapse.on("hide.bs.collapse", function () {
    const $button = getButton(this.id);
    setState($button, this.id, "hide");
  });

  $collapse.on("show.bs.collapse", function () {
    const $button = getButton(this.id);
    setState($button, this.id, "show");
  });
})();

// #############################################################################
// FANCY BOX

(function initFancyBox () {
  const $fancybox = $("[data-fancybox]");

  if ($fancybox.length === 0) {
    return;
  }

  $fancybox.fancybox({
    buttons: [
      "download",
      "fullScreen",
      "close",
    ],
    protect: true,
    afterLoad: function (instance, current) {
      const pixel_ratio = window.devicePixelRatio || 1;

      if (pixel_ratio > 1.5) {
        current.width = current.width / 2;
        current.height = current.height / 2;
      }
    },
  });
})();

(function initFancyBoxPDF () {
  const $fancybox = $("[data-fancybox-pdf]");

  if ($fancybox.length === 0) {
    return;
  }

  $fancybox.fancybox({
    toolbar: false,
    smallBtn: true,
    iframe: {
      preload: true,
    },
  });
})();


// #############################################################################
//  EXPAND TEXT

class ExpandText {
  constructor ($options) {
    const $defaults = {
      showLongTextSelector: "[data-show-long-text]",
      showShortTextSelector: "[data-show-short-text]",
    };

    this._$settings = { ...$defaults, ...$options };
  }

  init () {
    const _this = this;

    $x.delegateEvent.on(document, "click", _this._$settings.showLongTextSelector, function (e) {
      e.preventDefault();

      const [$shortText, $longText] = _this._getElements(this);

      $shortText.hidden = true;
      $longText.hidden = false;
    });

    $x.delegateEvent.on(document, "click", _this._$settings.showShortTextSelector, function (e) {
      e.preventDefault();

      const [$shortText, $longText] = _this._getElements(this);

      $shortText.hidden = false;
      $longText.hidden = true;
    });
  }

  _getElements ($element) {
    const $expandText = $element.closest("[data-expand-text]");
    const $shortText = $expandText.querySelector("[data-short-text]");
    const $longText = $expandText.querySelector("[data-long-text]");

    return [$shortText, $longText];
  }
}

const $expandText = new ExpandText();
$expandText.init();
